import React, {useEffect} from 'react';
import useAuth from "../hooks/useAuth";
import {Navigate, Outlet, RouteProps} from "react-router-dom"
import AuthService from "../services/auth.service";

const PrivateRoutes = (props: RouteProps) => {
    const {isAuthenticated} = useAuth();

    useEffect(() => {
    }, [isAuthenticated]);

    if (!isAuthenticated) {
        return <Navigate to="/login" replace/>;
    }

    return <Outlet/>;
};
export default PrivateRoutes;
