import SubscriptionList from "../modules/subscriptions/components/subscription-list";

const Subscriptions = () => {
    return (
        <main className='pt-5 h-full overflow-y-auto'>
            <SubscriptionList/>
        </main>
    )
}

export default Subscriptions
