import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import NotificationService from './notification.service';
import {environment} from "../../../environments/environment";
import {Authentication} from "../../auth/types/authentication.interface";
import {LocalStorageKeys} from "../types/local-storage-keys.interface";
import {RequestMethods} from "../types/request-options.interface";
import errorHandling from "./error-handling";

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    errorMessage?: string;
    successMessage?: string;
    isPublic?: boolean;
    skipDefault404Handling?: boolean;
    skipDefault401Handling?: boolean;
}

interface CustomError extends AxiosError {
    configMessage?: string;
    skipDefault404Handling?: boolean;
}

class ApiService {
    private axiosInstance: AxiosInstance;
    private readonly BASE_URL: string = environment.backendUrl;

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: `${this.BASE_URL}`,
        });
        this.axiosInstance.interceptors.response.use(
            this.handleSuccess,
            this.handleError.bind(this)
        );
    }

    private async http<T>(config: CustomAxiosRequestConfig): Promise<T> {
        try {
            if (!config.isPublic && this.authorizationToken) {
                if (!config.headers) {
                    config.headers = {};
                }
                config.headers.Authorization = "Bearer " + this.authorizationToken;
            }
            const response = await this.axiosInstance
                .request<T>(config)
                .then(res => {
                    if (config.successMessage) {
                        NotificationService.success(config.successMessage);
                    }
                    return res;
                });
            return response.data;
        } catch (error) {
            throw error as AxiosError
        }
    }

    public async get<T>(path: string, opts?: Omit<CustomAxiosRequestConfig, 'method'>): Promise<T> {
        return this.http<T>({
            method: RequestMethods.GET,
            url: path,
            ...opts
        });
    }

    public async post<T>(path: string, body?: any, opts?: Omit<CustomAxiosRequestConfig, 'method'>): Promise<T> {
        return this.http<T>({
            method: RequestMethods.POST,
            url: path,
            data: body,
            ...opts
        });
    }

    public async put<T>(path: string, body?: any, opts?: Omit<CustomAxiosRequestConfig, 'method'>): Promise<T> {
        return this.http<T>({
            method: RequestMethods.PUT,
            url: path,
            data: body,
            ...opts
        });
    }

    public async delete<T>(path: string, opts?: Omit<CustomAxiosRequestConfig, 'method'>): Promise<T> {
        return this.http<T>({
            method: RequestMethods.DELETE,
            url: path,
            ...opts
        });
    }

    private get authorizationToken(): string | undefined {
        const authenticationJson = localStorage.getItem(LocalStorageKeys.AUTHENTICATION);
        if (authenticationJson) {
            const authentication: Authentication = JSON.parse(authenticationJson);
            return authentication?.token.value;
        }
        return undefined;
    }

    private handleSuccess(response: AxiosResponse) {
        return response;
    }

    private handleError(error: CustomError) {
        if (error.response?.status === 404 && !!(error.response.config as any)?.skipDefault404Handling) {
            console.warn('Custom handling for 404, skipping default redirect.');
            errorHandling.handleDefault(error);
            return Promise.reject(error);
        }
        if (error.response?.status === 401 && !!(error.response.config as any)?.skipDefault401Handling) {
            console.warn('Custom handling for 401, skipping default redirect.');
            errorHandling.handleDefault(error);
            return Promise.reject(error);
        }

        errorHandling.handle(error);
        return Promise.reject(error);
    }
}

export default ApiService;
