import {useState} from 'react';
import PromoCodesApiService from "../services/promo-codes-api.service";
import {AxiosError} from "axios";
import {usePromoCode} from "../hooks/use-promo-code";
import {PromoCodeType} from "../types/promo-codes/promo-code-type.interface";
import {CheckIcon} from "@heroicons/react/16/solid";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";

interface PromoCodeProps {
    promoCodeType: PromoCodeType
}

const PromoCode = (props: PromoCodeProps) => {
    const LENGTH_PROMO_CODE: number = 8;
    const [promoCode, setPromoCode] = useState<string>('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const {applyPromoCode, clearPromoCode, isValidPromoCode} = usePromoCode();
    const {promoCodeType} = props

    const handleInputChange = (e: any) => {
        setPromoCode(e.target.value);
    };

    const clearLocalStates = () => {
        if (error) setError('');
        if (message) setMessage('');
    };

    const checkCode = async () => {
        clearLocalStates();
        if (promoCode && promoCode.length === LENGTH_PROMO_CODE) {
            await PromoCodesApiService
                .checkPromoCode(promoCode)
                .then(res => {
                    const data = applyPromoCode(promoCode,res, promoCodeType);
                    if (!data.isValidPromoCode) setErrorState();
                    else setMessage('Kod został aktywowany.')
                })
                .catch((err: AxiosError) => {
                    if (err.response?.status === 404) setErrorState();
                });
        } else {
            setErrorState();
            if (!promoCode.length) clearLocalStates();
        }
    };

    const setErrorState = () => {
        setError('Nieprawidłowy kod.');
        clearPromoCode();
    }

    return (
        <div className='p-1'>
            <header>
                <h3 className='mb-3 text-base leading-none font-normal text-gray-800 theme-dark:text-white'>
                    Posiadasz kod rabatowy? Wpisz go poniżej.
                </h3>
            </header>
            <div className='relative bg-white border-gray-300 shadow-sm border rounded-md appearance-none w-full h-full'>
                <input
                    placeholder='XXXXXXXX'
                    maxLength={8}
                    value={promoCode}
                    onBlur={checkCode}
                    onChange={handleInputChange}
                    className='text-center focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2
                p-[9px] rounded-md appearance-none w-full h-full'
                />
                {isValidPromoCode && <CheckIcon className='w-5 h-5 text-green-700 mr-2 absolute top-1/2 right-0 translate-y-[-50%]'/>}
                {!isValidPromoCode && error && <ExclamationTriangleIcon className='w-5 h-5 text-red-500 mr-2 absolute top-1/2 right-0 translate-y-[-50%]'/>}
            </div>

            {error && (
                <div className='text-red-500 text-sm leading-8 font-normal'>
                    {error}
                </div>
            )}

            {message && (
                <div className='text-green-700 text-sm leading-8 font-normal'>
                    {message}
                </div>
            )}
        </div>
    );
};

export default PromoCode;
