import React, {useEffect} from 'react';
import ScenarioRenderer from "./components/scenario-renderer";
import {BlockDTO} from "./types/get-blocks-response.interface";
import {mapToGenericBlock} from "./helpers/mapToGenericBlock";
import {useParams} from "react-router-dom";
import withDynamicViewProvider from "./hoc/withDynamicViewProvider";
import {useScenario} from "./hooks/use-scenario";
import {useDynamicViewApi} from "./hooks/use-dynamic-view-api";
import {TypeParent} from "./types/type-parent.interface";
import PageLoader from "../common/components/page-loader";

interface PropsDynamicView {
    scenario?: BlockDTO[];
    errorFetching?: string;
    titleParamName?: string;
    isCompleted?: boolean;
    typeView: TypeParent;
    parentName?: string;
    endAction?: () => void;
}

const DynamicView: React.FC<PropsDynamicView> = (props: PropsDynamicView) => {
    const params = useParams();
    let counterInteractiveBlocks = {current: 0};
    const {loading, error: axiosError, fetchBlocksDTO} = useDynamicViewApi();
    const {
        scenario: initialScenario,
        errorFetching = 'Nie udało się załadować danych.',
        titleParamName = '',
        typeView,
        isCompleted = false,
        parentName
    } = props
    let readonly: boolean = isCompleted ?? false;
    const {
        containerRef,
        error,
        setError,
        title,
        setTitle,
        setScenario,
        setIsReadonlyMode,
        setTypeParent,
        setEndAction
    } = useScenario();

    const initializeScenario = async () => {
        await fetchBlocksDTO(typeView, parentName)
            .then((res) => {
                const mappedBlocks = res.map((block, index) => mapToGenericBlock(block, counterInteractiveBlocks, index, readonly));
                setTitle((res[0]?.chapterName || res[0]?.screenName) ?? '');
                setScenario(mappedBlocks);
                setIsReadonlyMode(readonly)
            })
            .catch(() => setError(errorFetching))
            .finally()
    };

    useEffect(() => {
        setTypeParent(props.typeView);
        titleParamName && setTitle(params[titleParamName]!)

        if (initialScenario) {
            const mappedBlocks = initialScenario.map((block, index) => mapToGenericBlock(block, counterInteractiveBlocks, index, readonly));
            setScenario(mappedBlocks);
            setIsReadonlyMode(readonly);
        } else {
            initializeScenario()
                .then(() => {
                    setEndAction(() => props.endAction ?? null);
                });
        }
    }, [initialScenario]);


    if (loading) return <PageLoader/>;
    if (error) return <div>{errorFetching}</div>;

    return (
        <div
            className='flex flex-col sm:max-w-[700px] sm:mx-auto gap-6 p-1 overflow-x-hidden overflow-y-auto h-full max-h-full'
            ref={containerRef}>
            <header>
                <h4 className='text-base leading-none font-normal text-gray-700 theme-dark:text-gray-50'>
                    {title}
                </h4>
            </header>
            <ScenarioRenderer/>
        </div>
    );
};

export default withDynamicViewProvider(DynamicView);
